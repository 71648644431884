<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter();

void router.push({
    name: "settings",
});
</script>

<template>
    <div class="page-content-inner"></div>
</template>

<style lang="scss">
@import "bulma/sass/utilities/_all.sass";

.alarm-on {
    .navbar-brand {
        animation: alarmAnimation 2s infinite;
    }
}

@keyframes alarmAnimation {
    0% {
        background-color: $primary;
    }

    50% {
        background-color: $danger;
    }

    100% {
        background-color: $primary;
    }
}
</style>
