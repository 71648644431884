import { Kart } from "./../models/race/kart";
import { reactive, ref } from "vue";
import { useModal } from "addeus-common-library/stores/modal";
import { useCollection, useDoc } from "addeus-common-library/stores/firestore";
import { Device, Kind } from "../models/race/device";
import PromptComponent from "addeus-common-library/components/modal/Prompt.vue";
import VButton from "addeus-common-library/components/base/button/VButton.vue";
import MergeModal from "../components/modal/MergeModal.vue";
import { useTranslate } from "addeus-common-library/stores/translate";

export function useSWListener() {
    const globalNamespace = "model";
    const { translate } = useTranslate();
    const modalManager = useModal();

    function getModel(type: string): any | undefined {
        if (type === "kart") {
            return Kart;
        } else if (type === "device") {
            return Device;
        } else {
            return undefined;
        }
    }

    async function openDeviceMergeModal(device: Device | Kart, model: any) {
        const translateNamespace = `${globalNamespace}.${
            device["batteries"] !== undefined ? "kart" : "device"
        }.merge`;
        // obtenir la liste des composants
        const selected = ref(null);
        const devices = useCollection(model, {
            wheres: [
                ["owner", "==", device.owner.$getID()],
                ["kind", "==", device.kind],
                ["name", "!=", device.name],
            ],
        });
        const options = reactive({
            title: translate(`${translateNamespace}.title`, device).value,
            props: {
                entity: ref(device),
                collection: ref(devices),
            },
            isCloseDisabled: true,
            events: {
                async "update:select"(select) {
                    selected.value = select;
                },
            },
            actions: [
                {
                    component: VButton,
                    content: translate(`${translateNamespace}.save`).value,
                    props: {},
                    events: {
                        click() {
                            device.$edit();
                            modal.close();
                        },
                    },
                },
                {
                    component: VButton,
                    content: translate(`${translateNamespace}.merge`).value,
                    props: {
                        placeload: false,
                        color: "primary",
                    },
                    events: {
                        click() {
                            if (selected.value !== null) {
                                const entity: Device = selected.value;
                                entity.reference = device.reference;
                                if (device.version !== "0.0.0") {
                                    entity.version === device.version;
                                }
                                void entity.$save();
                                void device.$delete();
                            }
                            modal.close();
                        },
                    },
                },
            ],
        });
        const modal = modalManager.createModal(MergeModal, options);
    }

    async function openChoiceModal(device: Device | Kart, model: any) {
        const translateNamespace = `${globalNamespace}.${
            device["batteries"] !== undefined ? "kart" : "device"
        }.choice`;
        const options = reactive({
            title: translate(`${translateNamespace}.title`).value,
            props: {
                subTitle: translate(`${translateNamespace}.subtitle`, device).value,
                message: translate(`${translateNamespace}.message`, device).value,
            },
            isCloseDisabled: true,
            actions: [
                {
                    component: VButton,
                    content: translate(`${translateNamespace}.merge`).value,
                    props: {},
                    events: {
                        click() {
                            if (device.kind === Kind.kart) {
                                void openDeviceMergeModal(device, model);
                            } else {
                                void openDeviceMergeModal(device, model);
                            }
                            modal.close();
                        },
                    },
                },
                {
                    component: VButton,
                    content: translate(`${translateNamespace}.create`).value,
                    props: {
                        placeload: false,
                        color: "primary",
                        disabled: false,
                    },
                    events: {
                        click() {
                            void device.$edit();
                            modal.close();
                        },
                    },
                },
            ],
        });
        const modal = useModal().createModal(PromptComponent, options);
    }

    function listenToNewDevice() {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener("message", ({ data }) => {
                const model = getModel(data.type);
                if (model !== undefined) {
                    const doc = useDoc(model, data.id);
                    void openChoiceModal(doc, model);
                }
            });
        }
    }

    return {
        listenToNewDevice,
    };
}
