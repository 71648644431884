<script setup lang="ts">
import { provide, watch, computed } from "vue";
import { useUserSession } from "../stores/userSession";
import { useRouter, START_LOCATION } from "vue-router";
import { useTitle } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import extendModels from "../extend-models";
import { useSWListener } from "../stores/swListener";
import { useRace } from "../stores/track/race";

extendModels();
const userSession = useUserSession();
const router = useRouter();
const swListener = useSWListener();
const title = useTitle("Track2Max");
const { t } = useI18n();
useRace();

swListener.listenToNewDevice();

function getFirstAllowedRoute(): {} {
    const route = router.getRoutes().find((route) => {
        return (
            route.meta !== undefined &&
            route.meta.permissions !== undefined &&
            userSession.hasPermission(route.meta.permissions)
        );
    });
    if (!route)
        return {
            name: "settings",
        };
    return route;
}

provide("translate", () => {});
// Redirecto to settings if current user has no permissions
watch(
    () => userSession.user,
    async () => {
        await userSession.waitInitialized;

        if (userSession.user !== null && userSession.user !== undefined) {
            void userSession.user.role?.permissions;
            await userSession.user.role?.$getMetadata().isFullfilling;
        }
        if (
            router.currentRoute.value.meta !== undefined &&
            (router.currentRoute.value.meta.permissions !== undefined ||
                router.currentRoute.value.meta.requiresAuth === true) &&
            userSession.user === null
        ) {
            void router.push({
                path: "/auth/login",
            });
        } else if (
            router.currentRoute.value.meta !== undefined &&
            router.currentRoute.value.meta.permissions !== undefined &&
            userSession.hasPermission(router.currentRoute.value.meta.permissions) ===
                false
        ) {
            void router.push(getFirstAllowedRoute());
        }
    },
    { flush: "sync" }
);

watch(
    () => userSession.user?.role?.permissions,
    async () => {
        await userSession.waitInitialized;
        if (userSession.user === null) return;

        await userSession.user.role.$getMetadata().isFullfilling;

        if (
            router.currentRoute.value.meta !== undefined &&
            router.currentRoute.value.meta.permissions !== undefined &&
            userSession.hasPermission(router.currentRoute.value.meta.permissions) ===
                false
        ) {
            void router.push(getFirstAllowedRoute());
        }
    }
);

async function onRouteChange(route) {
    if (route.name !== undefined) {
        const translatedTitle = t(
            route.path.replaceAll("/", ".").substring(1) + ".title"
        );
        title.value = `Track2Max - ${translatedTitle}`;
    } else {
        title.value = `Track2Max`;
    }

    await userSession.waitInitialized;

    const isLoggedIn: boolean = userSession.isLoggedIn();

    if (route === START_LOCATION && isLoggedIn) {
        // 1. If the name is not set, it means we are navigating to the first page
        // and we are logged in, so we should check user information from the server
        try {
            if (
                route.meta.permissions !== undefined &&
                userSession.hasPermission(route.meta.permissions) === false
            ) {
                return getFirstAllowedRoute();
            }
        } catch (err) {
            // delete stored token if it fails
            void userSession.logout();

            if (route.meta.requiresAuth === true) {
                // redirect the user somewhere
                return {
                    // Will follow the redirection set in /@src/pages/auth/index.vue
                    name: "auth",
                    // save the location we were at to come back later
                    query: { redirect: route.fullPath },
                };
            }
        }
    } else if (
        (route.meta.requiresAuth === true || route.meta.permissions !== undefined) &&
        !isLoggedIn
    ) {
        return {
            // Will follow the redirection set in /@src/pages/auth/index.vue
            name: "auth",
            // save the location we were at to come back later
            query: { redirect: route.fullPath },
        };
    }
}

router.beforeEach(onRouteChange);
const newRoute = await onRouteChange(router.currentRoute.value);
if (newRoute) {
    void router.push(newRoute);
}

const isAlarmOn = computed(() => {
    return userSession.user?.owner?.isAlarmOn;
});
</script>
<template>
    <RouterView v-slot="{ Component, route }" :class="{ 'alarm-on': isAlarmOn }">
        <Transition name="translate-page-x" mode="out-in">
            <component :is="Component" :translate-namespace="route.name" />
        </Transition>
        <VReloadPrompt />
    </RouterView>
</template>

<style lang="scss">
html,
body {
    // disable pull to refresh
    overscroll-behavior: none;
}
</style>
